@tailwind base;
@tailwind components;
@tailwind utilities;

th {
    cursor: pointer;
    user-select: none;
}

.leaflet-container{
    max-height: 500px;
    min-height: 50vh;
}

@font-face{
    font-family: 'LUFGA';
    src: url('./fonts/Numans-Regular.ttf');
}